.messageBox {
  background: #f3f3f3;
  padding: 2% 6%;
  color: white;
  display: inline-block;
  max-width: 80%;
  margin: 3% 0;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.messageText {
  white-space: normal;
  letter-spacing: 0;
  font-size: 1em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.2em;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 5%;
}

.adminEdge {
}

.senderEdge {
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #a0a0a0;
  letter-spacing: 0.3px;
}

.adminText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #a0a0a0;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorDark {
  color: #adadad;
}

.colorAdmin {
  color: #adadad;
}

.colorWhite {
  color: #000;
}

.colorDark a {
  color: #0096dbc5;
}

.colorWhite a {
  color: #00327c;
}
.backgroundLight {
  background: #111;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.backgroundBlue {
  background: #01a1ce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundAdmin {
  background: #111; /* fallback for old browsers */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundGreen {
  background: #cb356b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundOrange {
  background: #cb356b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #01a1ce,
    #cb356b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #01a1ce,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.firstMessage {
  padding-top: 15%;
}
