.helpMenu {
  color: rgb(190, 190, 190);
}

.helpMenu h3 {
  padding-top: 5%;
  padding-left: 10%;
  background: #cb356b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features li {
  line-height: 1.2em;
  margin-bottom: 7%;
}

.textCommands li {
  line-height: 1.2em;
  margin-bottom: 7%;
}

.textCommands li span {
  font-weight: bold;
  color: #cb356b;
}
