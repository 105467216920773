.side-bar-container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.side-bar {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  background: rgba(0, 0, 0);
  height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  z-index: 30;
  border-top-right-radius: 0em;
  border-bottom-right-radius: 20%;
  box-shadow: 1em 0em 2em rgb(7, 7, 7);
  padding-right: 8%;
  overflow: auto;
}
