.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
  color: black;
}

.joinInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(100, 100, 100);
  opacity: 1; /* Firefox */
}

.joinInput:focus {
  color: black;
  outline: none;
}

.mt-20 {
  margin-top: 20px;
}

.input-error {
  color: rgb(238, 61, 61);
  padding: 15px 20px;
  font-size: 0.8em;
}
