.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
  color: black;
}

.joinInput::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(100, 100, 100);
  opacity: 1; /* Firefox */
}

.joinInput:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(100, 100, 100);
  opacity: 1; /* Firefox */
}

.joinInput::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(100, 100, 100);
  opacity: 1; /* Firefox */
}

.joinInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(100, 100, 100);
  opacity: 1; /* Firefox */
}

.joinInput:focus {
  color: black;
  outline: none;
}

.mt-20 {
  margin-top: 20px;
}

.input-error {
  color: rgb(238, 61, 61);
  padding: 15px 20px;
  font-size: 0.8em;
}

html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinInnerContainer {
  width: 80%;
}

.joinInput {
  border-radius: 10em;
  padding: 15px 20px;
  width: 100%;
}

.joinInnerContainer .heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.joinInnerContainer .button {
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-decoration: none;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 20px;
  display: inline-block;
  border: none;
  border-radius: 10em;
  width: 100%;
  cursor: pointer;
}

.joinInnerContainer .mt-20 {
  margin-top: 20px;
}

html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1a1a1d;
}

.joinInnerContainer {
  width: 80%;
}

.joinInnerContainer .heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.joinInnerContainer .button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 20px;
  border-radius: 10em;
  display: inline-block;
  border: none;
  width: 100%;
  cursor: pointer;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}

.landingWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: #000;
}

.menuWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 2em 0;
  margin-bottom: 1em;
}

.menuWrapper button {
  background: transparent;
  border: none;
  color: #aaa;
  padding: 0.6em;
  border-radius: 2em;
  font-size: 1.5em;
  letter-spacing: 0.1em;
  border: 0.1em solid #aaa;
  cursor: pointer;
}

.menuWrapper .currSelection {
  font-weight: bold;
  font-size: 2.2em;
  border: none;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: default;
}

@media (min-width: 640px) {
  .landingWrapper {
    width: 600px;
    height: 95%;
  }

  .menuWrapper {
    width: 100%;
  }
}

.userDataContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: calc(50% - 100px);
  color: #d2d2d2;
}

.userDataContainer div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  padding: 3%;
  margin: 0;
}

.userDataContainer img {
  padding-right: 1em;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.navBar {
  display: flex;
  justify-content: space-around;
  padding: 5% 0;
}

.navBar button {
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding-bottom: 0.5em;
  border: none;
  cursor: pointer;
}

.navBar img {
  width: 2.2em;
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.navBar .addShadow {
  width: 2.8em;
}

.side-bar-container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.side-bar {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  background: rgba(0, 0, 0);
  height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  z-index: 30;
  border-top-right-radius: 0em;
  border-bottom-right-radius: 20%;
  box-shadow: 1em 0em 2em rgb(7, 7, 7);
  padding-right: 8%;
  overflow: auto;
}

.back-drop {
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 20;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.titleBarHome {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  color: rgb(190, 190, 190);
}

.hiButton {
  border: none;
  letter-spacing: 0.1em;
  font-size: 1.6em;
  font-weight: 600;
  color: white;
  background: transparent;
  margin: 5% 0;
  cursor: pointer;
}

.logoutButton {
  background: transparent;
  border: none;
  color: rgb(190, 190, 190);
  font-size: 1em;
  cursor: pointer;
}

.humansOnline {
  text-align: center;
  padding: 0 5%;
  font-size: 1em;
  line-height: 1.3em;
  color: rgb(210, 210, 210);
}

.findHumans {
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  font-size: 1.6em;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: black;
  border: none;
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  position: relative;
  padding: 2em;
  cursor: pointer;
}

.chatButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.helpMenu {
  color: rgb(190, 190, 190);
}

.helpMenu h3 {
  padding-top: 5%;
  padding-left: 10%;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features li {
  line-height: 1.2em;
  margin-bottom: 7%;
}

.textCommands li {
  line-height: 1.2em;
  margin-bottom: 7%;
}

.textCommands li span {
  font-weight: bold;
  color: #cb356b;
}

.titleBarRooms {
  padding: 0 5%;
  color: rgb(190, 190, 190);
}

.roomList {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  flex-grow: 1;
  margin: 1em 0 0 0;
}

.roomList li {
  list-style-type: none;
  display: flex;
  margin: 1.1em 0;
  width: 100%;
}

.numOnline {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10%;
  color: #22031f;
  font-weight: bolder;
  background-color: #0dcc86;
}

.roomButton {
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 80%;
  white-space: nowrap;
  margin: 0;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#01a1ce),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #01a1ce,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: black;

  padding: 2em 0;
}

.roomList li:nth-child(even) {
  display: flex;
  justify-content: flex-end;
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
}

.roomList li:nth-child(even) button {
  align-items: flex-end;
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
  padding-left: 4em;
  padding-right: 2em;
  cursor: pointer;
}

.roomList li:nth-child(even) input {
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
}

.roomList li:nth-child(odd) {
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
}

.roomList li:nth-child(odd) button {
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  padding-left: 2em;
  padding-right: 4em;
  cursor: pointer;
}

.roomList li:nth-child(odd) input {
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
}

.roomList li:nth-child(odd) .roomButton h3 {
  margin: 0 0 1em 0;
  font-size: 1.3em;
  letter-spacing: 0.1em;
  padding: 0;
  text-align: left;
}

.roomList li:nth-child(odd) .roomButton h5 {
  margin: 0;
  padding: 0;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-align: left;
}

.roomList li:nth-child(even) .roomButton h3 {
  margin: 0 0 1em 0;
  font-size: 1.3em;
  letter-spacing: 0.1em;
  padding: 0;
  text-align: right;
}

.roomList li:nth-child(even) .roomButton h5 {
  margin: 0;
  padding: 0;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-align: right;
}

.createRoomItem {
  width: 100%;
  position: relative;
  height: 123.45px;
}

.createRoomItem input {
  height: 100%;
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#01a1ce),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #01a1ce,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  text-decoration: none;
  color: #111111;
}

.createRoomItem input:invalid {
  background-color: rgba(0, 0, 0, 0.5);
}

.createRoomItem input:focus {
  color: rgba(0, 0, 0);
}

.createRoomItem input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.801);
  opacity: 1; /* Firefox */
}

.createRoomItem input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.801);
  opacity: 1; /* Firefox */
}

.createRoomItem input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.801);
  opacity: 1; /* Firefox */
}

.createRoomItem input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.801);
  opacity: 1; /* Firefox */
}

.container .roomList li .createRoom {
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#01a1ce),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #01a1ce,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border: none;
  font-weight: bolder;
  font-size: 2em;
  color: black;
  height: 100%;
  padding: 0 1em;
}

.createRoomReposition {
  background: #8e9eab; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#eef2f3),
    to(#8e9eab)
  );
  background: linear-gradient(
    to left,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border: none;
  font-weight: bold;
  font-size: 2em;
  color: rgb(12, 12, 12);
  padding: 0;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20em;
  top: 3em;
}

.roomList li:nth-child(odd) .createRoomReposition {
  position: absolute;
  left: 3em;
}

.roomList li:nth-child(even) .createRoomReposition {
  position: absolute;
  right: 3em;
}

.noFriendsWrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}

.noFriends {
  background: #01a1ce; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#8e54e9),
    to(#01a1ce)
  );
  background: linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 7% 10% 7% 7%;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.titleBarFriends {
  padding: 0 5%;
  color: rgb(190, 190, 190);
}

.friendsList {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  flex-grow: 1;
  margin: 1em 0 0 0;
}

.friendsListItem {
  display: flex;
  margin: 1.1em 0;
  width: 100%;
}

.dmButton {
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #01a1ce; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#8e54e9),
    to(#01a1ce)
  );
  background: linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 1.5em 3em 1.5em 2em;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  cursor: pointer;
}

.dmButton h3 {
  margin: 0 0 0.4em 0;
  text-align: left;
  color: rgb(26, 26, 26);
  font-size: 1.3em;
  width: 100%;
  letter-spacing: 0.1em;
}

.dmButton p {
  margin: 0 0;
  font-family: Helvetica;
  color: rgb(24, 24, 24);
  width: 100%;
  white-space: nowrap;
  font-size: 1em;
  overflow-x: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.05em;
}

.userTimerBadge {
  background: transparent; /* fallback for old browsers */

  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: 50em;
  border-bottom-right-radius: 80em;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 8;
}

.userTimerBadge p {
  background: #cb356b; /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 1.3em;
  font-weight: 700;
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  right: 7%;
}

.peerTimerBadge {
  background: transparent; /* fallback for old browsers */

  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: 50em;
  border-bottom-right-radius: 80em;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 8;
}

.peerTimerBadge p {
  color: rgb(190, 190, 190);
  padding: 0;
  margin: 0;
  font-size: 1.3em;
  font-weight: 700;
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  right: 7%;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  height: 7%;
  width: 100%;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 30%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  z-index: 7;
  box-shadow: 1em 0 1em #111;
}

.backButton {
  border: none;
  height: 100%;
  padding: 0 1em 0 0.5em;
  border-bottom-right-radius: 80em;
  background-color: transparent;
  cursor: pointer;
}

.backIcon {
  vertical-align: middle;
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.leftContainer {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0% 15% 0% 4%;
}

.roomNameBar {
  font-size: 1.3em;
  letter-spacing: 0.1em;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(190, 190, 190);
  border: none;
  height: 100%;
}

.addToRoom {
  height: 100%;
  padding: 0;
  margin: 0 5% 0 0;
  background: transparent;
  color: white;
  border: none;
  border-bottom-left-radius: 80em;
  cursor: pointer;
}

.addToRoomIcon {
  width: 2.2em;
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.leftRadius {
  border-bottom-left-radius: 80em;
}

.rightRadius {
  border-bottom-right-radius: 80em;
}

.form {
  height: 7%;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 7;
  border-top-left-radius: 30%;
  border-top-right-radius: 20%;
  box-shadow: 1em 0 1em #111;
}

.input {
  border-top-left-radius: 25%;
  border-bottom-right-radius: 0em;
  border-bottom-left-radius: 0px;
  padding: 0% 5%;
  width: 100%;
  font-size: 1.2em;
  caret-color: white;
  background-color: transparent;
  border: none;
  color: white;
}

/* Need to fix this as well */
input:focus,
textarea:focus,
select:focus {
  outline: none;
  color: white;
}

.input:invalid {
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(201, 201, 201);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(201, 201, 201);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(201, 201, 201);
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(201, 201, 201);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(58, 58, 58);
}

.sendButton {
  cursor: pointer;
  background-color: transparent;
  padding: 0px;
  border: none;
  width: 15%;
  border-bottom-left-radius: 0em;
  border-top-left-radius: 0em;
  border-top-right-radius: 70%;
  height: 100%;
  position: relative;
}

.sendIcon {
  position: absolute;
  -webkit-filter: invert(1);
          filter: invert(1);
  top: 35%;
  left: 30%;
  max-width: 35%;
  opacity: 0.8;
}

.chatOverButtons {
  display: flex;
  justify-content: center;
  height: 7%;
  z-index: 7;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-top-left-radius: 30%;
  border-top-right-radius: 20%;
}

.skipButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  background: transparent;
  border-top-left-radius: 30%;
  border-top-right-radius: 20%;
  color: rgb(190, 190, 190);
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 1.4em;
}

.messageBox {
  background: #f3f3f3;
  padding: 2% 6%;
  color: white;
  display: inline-block;
  max-width: 80%;
  margin: 3% 0;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.messageText {
  white-space: normal;
  letter-spacing: 0;
  font-size: 1em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.2em;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 5%;
}

.adminEdge {
}

.senderEdge {
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #a0a0a0;
  letter-spacing: 0.3px;
}

.adminText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #a0a0a0;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorDark {
  color: #adadad;
}

.colorAdmin {
  color: #adadad;
}

.colorWhite {
  color: #000;
}

.colorDark a {
  color: #0096dbc5;
}

.colorWhite a {
  color: #00327c;
}
.backgroundLight {
  background: #111;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.backgroundBlue {
  background: #01a1ce; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#8e54e9),
    to(#01a1ce)
  );
  background: linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundAdmin {
  background: #111; /* fallback for old browsers */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundGreen {
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#8e54e9),
    to(#cb356b)
  );
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.backgroundOrange {
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#01a1ce),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #01a1ce,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.firstMessage {
  padding-top: 15%;
}

.emptySpace {
  height: 4%;
  width: 100%;
  position: absolute;
  background-color: black;
  z-index: 6;
}

.emptySpace2 {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: black;
  z-index: 6;
}

.messages {
  overflow: auto;
  flex: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  z-index: 5;
}

.addFriendsList {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.addFriendsListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 2em;
  padding: 0;
  width: 100%;
  margin: 0 0 1em 0;
  list-style: none;
}

.addFriendNameWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0 0 0 0;
  list-style: none;
  height: 100%;
}

.addFriendName {
  height: 100%;
  border: none;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#f15f79),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #f15f79,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  letter-spacing: 0.1em;
  border-top-right-radius: 20em;
  border-bottom-right-radius: 20em;
}

.addFriendsListItem p {
  display: flex;
  align-items: center;
  color: rgb(190, 190, 190);
  margin: 0 1.2em;
}

.addFriend {
  display: flex;
  align-items: center;
  height: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
}

.addFriendsIcon {
  -webkit-filter: invert(1);
          filter: invert(1);
  width: 1.8em;
}

.onlineList {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.onlineListItem {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 2em 0;
  list-style: none;
  height: 2em;
  width: 100%;
}

.barHeading {
  white-space: nowrap;
  color: rgb(190, 190, 190);
  padding: 0.2em 0.7em;
  text-align: left;
  font-size: 1em;
}

.roomMember {
  height: 100%;
  width: 2em;
  text-align: left;
  border: none;
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#f15f79),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #f15f79,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  letter-spacing: 0em;
  border-top-right-radius: 20em;
  border-bottom-right-radius: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1em;
}

.roomMemberWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 0 0;
  list-style: none;
  height: 100%;
}

.onlineListItem p {
  display: flex;
  align-items: center;
  color: rgb(190, 190, 190);
  margin: 0 1.2em;
  letter-spacing: 0.1em;
}

.onlineState {
  background: #11998e; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#0dcc86),
    to(#11998e)
  );
  background: linear-gradient(
    to right,
    #0dcc86,
    #11998e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.deleteMemberButton {
  padding: 0 0 0 5%;
  height: 100%;
  border: none;
  background: transparent;
  vertical-align: middle;
  cursor: pointer;
}

.deleteMemberIcon {
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
  width: 1.8em;
}

.returnToRooms {
  background: #cb356b; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#f15f79),
    to(#cb356b)
  );
  background: linear-gradient(
    to right,
    #f15f79,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: black;
  font-weight: bold;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  border: none;
  display: flex;
  align-items: center;
  padding: 1em 0;
  margin-bottom: 15%;
  width: 7em;
}

.returnIcon {
  -webkit-filter: invert(0);
          filter: invert(0);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin: 0 7%;
  width: 1.5em;
}

.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #1a1a1d; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: radial-gradient(
    #000 20%,
    #1a1a1d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
}

@media (min-width: 640px) {
  .container {
    width: 600px;
    height: 95%;
  }
}

