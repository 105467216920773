.navBar {
  display: flex;
  justify-content: space-around;
  padding: 5% 0;
}

.navBar button {
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding-bottom: 0.5em;
  border: none;
  cursor: pointer;
}

.navBar img {
  width: 2.2em;
  filter: invert(0.8);
}

.navBar .addShadow {
  width: 2.8em;
}
