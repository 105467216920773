.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  height: 7%;
  width: 100%;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 30%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  z-index: 7;
  box-shadow: 1em 0 1em #111;
}

.backButton {
  border: none;
  height: 100%;
  padding: 0 1em 0 0.5em;
  border-bottom-right-radius: 80em;
  background-color: transparent;
  cursor: pointer;
}

.backIcon {
  vertical-align: middle;
  filter: invert(0.8);
}

.leftContainer {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0% 15% 0% 4%;
}

.roomNameBar {
  font-size: 1.3em;
  letter-spacing: 0.1em;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(190, 190, 190);
  border: none;
  height: 100%;
}

.addToRoom {
  height: 100%;
  padding: 0;
  margin: 0 5% 0 0;
  background: transparent;
  color: white;
  border: none;
  border-bottom-left-radius: 80em;
  cursor: pointer;
}

.addToRoomIcon {
  width: 2.2em;
  filter: invert(0.8);
}

.leftRadius {
  border-bottom-left-radius: 80em;
}

.rightRadius {
  border-bottom-right-radius: 80em;
}
