.form {
  height: 7%;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 7;
  border-top-left-radius: 30%;
  border-top-right-radius: 20%;
  box-shadow: 1em 0 1em #111;
}

.input {
  border-top-left-radius: 25%;
  border-bottom-right-radius: 0em;
  border-bottom-left-radius: 0px;
  padding: 0% 5%;
  width: 100%;
  font-size: 1.2em;
  caret-color: white;
  background-color: transparent;
  border: none;
  color: white;
}

/* Need to fix this as well */
input:focus,
textarea:focus,
select:focus {
  outline: none;
  color: white;
}

.input:invalid {
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(201, 201, 201);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(58, 58, 58);
}

.sendButton {
  cursor: pointer;
  background-color: transparent;
  padding: 0px;
  border: none;
  width: 15%;
  border-bottom-left-radius: 0em;
  border-top-left-radius: 0em;
  border-top-right-radius: 70%;
  height: 100%;
  position: relative;
}

.sendIcon {
  position: absolute;
  filter: invert(1);
  top: 35%;
  left: 30%;
  max-width: 35%;
  opacity: 0.8;
}

.chatOverButtons {
  display: flex;
  justify-content: center;
  height: 7%;
  z-index: 7;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-top-left-radius: 30%;
  border-top-right-radius: 20%;
}

.skipButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  background: transparent;
  border-top-left-radius: 30%;
  border-top-right-radius: 20%;
  color: rgb(190, 190, 190);
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 1.4em;
}
