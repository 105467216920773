.noFriendsWrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}

.noFriends {
  background: #01a1ce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 7% 10% 7% 7%;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  width: fit-content;
}

.titleBarFriends {
  padding: 0 5%;
  color: rgb(190, 190, 190);
}

.friendsList {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  flex-grow: 1;
  margin: 1em 0 0 0;
}

.friendsListItem {
  display: flex;
  margin: 1.1em 0;
  width: 100%;
}

.dmButton {
  display: flex;
  flex-direction: column;
  border: none;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #01a1ce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #8e54e9,
    #01a1ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 1.5em 3em 1.5em 2em;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  cursor: pointer;
}

.dmButton h3 {
  margin: 0 0 0.4em 0;
  text-align: left;
  color: rgb(26, 26, 26);
  font-size: 1.3em;
  width: 100%;
  letter-spacing: 0.1em;
}

.dmButton p {
  margin: 0 0;
  font-family: Helvetica;
  color: rgb(24, 24, 24);
  width: 100%;
  white-space: nowrap;
  font-size: 1em;
  overflow-x: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.05em;
}
