.titleBarHome {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  color: rgb(190, 190, 190);
}

.hiButton {
  border: none;
  letter-spacing: 0.1em;
  font-size: 1.6em;
  font-weight: 600;
  color: white;
  background: transparent;
  margin: 5% 0;
  cursor: pointer;
}

.logoutButton {
  background: transparent;
  border: none;
  color: rgb(190, 190, 190);
  font-size: 1em;
  cursor: pointer;
}

.humansOnline {
  text-align: center;
  padding: 0 5%;
  font-size: 1em;
  line-height: 1.3em;
  color: rgb(210, 210, 210);
}

.findHumans {
  background: #cb356b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  font-size: 1.6em;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: black;
  border: none;
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  position: relative;
  padding: 2em;
  cursor: pointer;
}

.chatButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
