.userTimerBadge {
  background: transparent; /* fallback for old browsers */

  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: 50em;
  border-bottom-right-radius: 80em;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 8;
}

.userTimerBadge p {
  background: #cb356b; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #8e54e9, #cb356b);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8e54e9,
    #cb356b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 1.3em;
  font-weight: 700;
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  right: 7%;
}

.peerTimerBadge {
  background: transparent; /* fallback for old browsers */

  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: 50em;
  border-bottom-right-radius: 80em;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 8;
}

.peerTimerBadge p {
  color: rgb(190, 190, 190);
  padding: 0;
  margin: 0;
  font-size: 1.3em;
  font-weight: 700;
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  right: 7%;
}
