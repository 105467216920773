.emptySpace {
  height: 4%;
  width: 100%;
  position: absolute;
  background-color: black;
  z-index: 6;
}

.emptySpace2 {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: black;
  z-index: 6;
}

.messages {
  overflow: auto;
  flex: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  z-index: 5;
}
