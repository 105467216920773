.userDataContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: calc(50% - 100px);
  color: #d2d2d2;
}

.userDataContainer div {
  width: fit-content;
  display: flex;
  padding: 3%;
  margin: 0;
}

.userDataContainer img {
  padding-right: 1em;
  filter: invert(1);
}
